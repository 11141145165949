.loader-container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: auto;
}

@keyframes scalarLightning {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.loader-lightning {
    animation: scalarLightning 3s infinite;
    width: auto;
    height: 100px;
}
