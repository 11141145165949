/* Form Products */
.container-form-products {
    max-width: 70%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-text,
.input-number,
.input-file,
.checkbox-label {
    display: block;
    margin-bottom: 15px;
}

.input-text,
.input-number,
.input-file {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
}

.input-text, .input-number {
    border: 1px solid #ccc;
}

.input-file {
    padding: 10px 0;
}

.checkbox-label {
    cursor: pointer;
    font-size: 16px;
}

.save-button {
    display: block;
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}

.save-button:disabled, .logout-button:disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
}

.logout-button {
    margin-top: 30px;
    background-color: #dc3545;
    padding: 10px 20px;
    color: #FFFFFF;
}

.logout-button:hover {
    background-color: #bd2130;
}

.image-preview {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-preview img {
    display: block;
    margin-top: 10px;
    max-width: 100%;
    height: auto;
}

.image-preview p {
    margin-top: 10px;
    font-size: 14px;
}

.image-preview a {
    color: #007bff;
}