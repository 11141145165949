.category-img {
    height: 150px !important;
    object-fit: contain !important;
    padding: 15px;
}

.category-title {
    text-align: center !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    padding: 10px !important;
}

.category-item {
    border-radius: 15px;
    transition: background 0.3s ease !important;
}

.category-item-loading {
    height: 200px;
    margin: 1rem;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 400% 100%;
    animation: loading 1.5s infinite;
}

.category-item.active, .category-item:hover {
    background: #F9B40D !important;
}

@keyframes loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
