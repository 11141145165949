.card-grid {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Define el tamaño mínimo y máximo de las columnas */
}

.card {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.card-container-img {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-img {
    width: 100%;
    height: auto;
    /* Add cover */
    object-fit: contain;
}

.card-title, .card-prize {
    padding: 10px 0;
    width: 100%;
}

.card-title:hover {
    transition: color 0.3s ease;
}

.card-prize {
    width: 100%;
    font-weight: bold;
    text-align: end;
}

.card-title:hover {
    color: #5780BD;
}

.card-button {
    width: 100%;
    background: #1C293D;
    color: #FFFFFF;
    padding: 5px 10px;
    margin: 10px 0;
    transition: transform 0.3s;
}

.card-button:hover {
    transform: scale(1.1);
}

.card-footer {
    width: 100%;
    margin-top: auto;
}

/* Media queries para tamaños de pantalla específicos */
@media screen and (max-width: 767px) {
    .card-grid {
        grid-template-columns: repeat(1, 1fr); /* Mostrar 2 columnas en pantallas pequeñas */
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .card-grid {
        grid-template-columns: repeat(2, 1fr); /* Mostrar 3 columnas en tabletas */
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .card-grid {
        grid-template-columns: repeat(3, 1fr); /* Mostrar 3 columnas en tabletas */
    }
}

@media screen and (min-width: 1440px) {
    .card-grid {
        grid-template-columns: repeat(4, 1fr); /* Mostrar 4 columnas en pantallas más grandes */
    }
}

.no-products-container, .products-loading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 1rem; /* Ajusta según sea necesario */
}

.no-products-image {
    width: 200px; /* Ajusta el tamaño de la imagen según sea necesario */
}

.no-products-text {
    color: #CCCCCC;
    font-weight: bold;
    margin-top: 1rem; /* Espaciado entre la imagen y el texto */
    font-size: 1.2em;
    text-align: center; /* Centra el texto */
}

.lds-dual-ring {
    color: #1C293D;
}

.lds-dual-ring,
.lds-dual-ring:after {
    box-sizing: border-box;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.btn-pagination-prev, .btn-pagination-next {
    padding: 5px 20px;
    background: #F5F5F5;
    border: 2px solid #1C293D;
    color: #1C293D;
    font-weight: bold;
    transition: all 0.3s ease;
}

.btn-pagination-prev:hover, .btn-pagination-next:hover {
    background: #1C293D;
    color: #FFFFFF;
}

.btn-pagination-prev:disabled,
.btn-pagination-next:disabled {
    background: #E0E0E0;
    border: 2px solid #CCCCCC;
    color: #CCCCCC;
    transition: none;
    cursor: not-allowed;
}

.btn-pagination-prev {
    margin-right: 10px;
}

.btn-pagination-next {
    margin-left: 10px;
}

.container-pagination {
    display: flex;
    align-items: center;
    padding: 20px 0;
    flex-direction: row;
}

.container-text-pagination, .container-buttons-pagination {
    flex-basis: 50%;
    max-width: 50%;
}

.container-buttons-pagination {
    display: flex;
    justify-content: flex-end;
}

.text-pagination {
    font-size: 1.2em;
    color: #1C293D;
    font-weight: bold;
}