.container-product-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.product-images, .product-data {
    flex-basis: 50%;
}

.product-images, .product-data {
    margin-right: 1.5rem;
}

.product-data {
    margin-left: 1.5rem;
}

.product-images {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-title {
    font-size: 1.5em;
    font-weight: bold;
}

.product-description {
    font-size: 1.1em;
}

.product-price {
    font-size: 1.5em;
    font-weight: bold;
}

.btn-add-to-cart {
    background-color: #1C293D;
    color: #ffffff;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    transition: transform .5s ease-in-out;
}

.btn-add-to-cart:hover {
    transform: scale(1.1);
}

.product-title, .product-description, .product-price, .btn-add-to-cart {
    margin: 1rem 0;
}

.product-feature {
    font-size: 1.1em;
    padding: .5rem 0;
}

.container-banner-shazam {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    background: #FFB301;
    margin: 2rem 0;
}