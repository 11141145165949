.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    position: fixed;
    width: 100%;
    background: #FFFFFF;
}

.space-navbar {
    height: 82px;
}

.navbar__left {
    display: flex;
    align-items: center;
    width: 100%; /* El 100% del espacio disponible */
}

.navbar__logo {
    width: 125px; /* Tamaño del logo */
}

.navbar__search {
    display: flex;
    align-items: center;
    margin-left: 15px; /* Espacio entre el logo y el input de búsqueda */
    border: 1px solid #CCCCCC;
    border-radius: 50px; /* Border radius de 50% */
    padding: 10px 15px;
    width: 100%;
}

.navbar__search input[type="text"] {
    width: 100%; /* El input ocupa todo el espacio disponible */
    margin-right: 5px;
    border: none;
}

.navbar__search input[type="text"]::placeholder {
    color: #CCCCCC; /* Cambia el color del texto del placeholder */
}

.navbar__search input[type="text"]:focus {
    outline: none; /* Quita el contorno predeterminado al enfocar el input */
    border-color: transparent; /* Quita el borde al enfocar el input */
}

.search-button {
    background: none;
    border: none;
    padding: 0;
}

.navbar__right {
    display: flex;
    align-items: center;
}

.navbar__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px; /* Espacio entre los links */
    position: relative;
    color: #1C293D; /* Color del texto */
}

.badge {
    background-color: #F9B40D;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    position: absolute;
    top: -5px; /* Posicionamiento arriba */
    right: -5px; /* Posicionamiento a la derecha */
}

.svg-icon {
    fill: #1C293D; /* Color deseado para el SVG */
    width: 24px; /* Ajusta el tamaño según lo necesites */
    height: 24px; /* Ajusta el tamaño según lo necesites */
}

.svg-icon.search {
    width: 20px; /* Ajusta el tamaño según lo necesites */
    height: 20px; /* Ajusta el tamaño según lo necesites */
}

.section {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 100%;
}

.footer {
    padding-left: 1rem;
    padding-right: 1rem;
    background: #1C293D;
    color: #FFFFFF;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
}

.divider-title {
    width: 100%;
    height: 2px;
    background: #CCCCCC;
}

.section-title {
    position: absolute;
    text-transform: uppercase;
    font-weight: bold;
    background: #F5F5F5;
    padding: 0 .5rem;
    color: #000000;
}

.footer-content {
    padding: 1.5rem 0 1rem 0;
}

.contact-info, .social-media {
    flex-basis: 100%;
}

.contact-item {
    padding: .5rem 0;
}

.contact-item.title {
    text-transform: uppercase;
    font-weight: bold;
}

.copyright {
    text-align: center;
    padding: .5rem .5rem;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.social-icons-item {
    margin: 0 .5rem;
}

.social-icon {
    fill: #FFFFFF;
    width: 30px;
    height: 30px;
}

@media screen and (min-width: 768px) {
    .navbar {
        padding: 1.5rem 5rem;
    }

    .space-navbar {
        height: 98px;
    }

    .section, .footer {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .footer-content {
        display: flex;
    }

    .contact-info, .social-media {
        flex-basis: 50%;
    }

    .contact-info {
        padding-right: .5rem;
    }

    .social-media {
        padding-left: .5rem;
    }
}

@media screen and (min-width: 1024px) {
    .navbar, .section, .footer {
        padding-left: 10rem;
        padding-right: 10rem;
    }
}

@media screen and (min-width: 1440px) {
    .navbar, .section, .footer {
        padding-left: 20rem;
        padding-right: 20rem;
    }
}
