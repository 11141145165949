.not-found-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.not-found-brand {
    width: 300px;
    height: auto;
    padding: 2rem 1rem;
}

.not-found-text {
    font-size: 1.5em;
    padding: 0 1rem;
}
